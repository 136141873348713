import React from "react";
import { connect } from "react-redux";
import { withRouter } from "shared/withRouter";
import { Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { TemplateService } from "shared/services";
import axios from "axios";

import "./TemplateCloneModal.scss";

class TemplateCloneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloneModal: {
        show: false,
        templateName: "",
        inProgress: false,
        template: null,
      },
      clonePercentage: 0,
    };
  }

  componentDidMount() {
    this.openCloneModal();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.template) !== JSON.stringify(this.props.template)
    ) {
      this.openCloneModal();
    }
  }

  openCloneModal = () => {
    if (this.props.template) {
      this.templateClone(this.props.template);
      if (this.props.cleanCloneTemplate) {
        this.props.cleanCloneTemplate();
      }
    }
  };

  templateClone = (template) => {
    this.setState({
      cloneModal: {
        ...this.state.cloneModal,
        show: true,
        templateName: `Copy of ${template.name}`,
        template: template,
      },
    });
  };

  closeCloneModal = () => {
    console.log("CLOSING");
    this.setState({
      cloneModal: {
        // ...this.state.cloneModal,
        show: false,
        templateName: "",
        inProgress: false,
        template: null,
      },
    });
    if (this.props.cleanCloneTemplate) {
      this.props.cleanCloneTemplate();
    }
  };

  templateNameChange = (event) => {
    this.setState({
      cloneModal: {
        ...this.state.cloneModal,
        templateName: event.target.value,
      },
    });
  };

  stopCloneProgress = () => {
    this.setState({
      cloneModal: {
        ...this.state.cloneModal,
        inProgress: false,
      },
    });
  };

  cloneProgress = (percentage, add = 0) => {
    this.setState({ clonePercentage: percentage + add });
  };

  fileProgress = (progressEvent, secondFile = false) => {
    let percentComplete = progressEvent.loaded / progressEvent.total;
    percentComplete = parseInt(percentComplete * 100);
    this.setState({
      clonePercentage:
        (secondFile ? 75 : 50) +
        parseFloat(parseFloat(percentComplete / 4).toFixed(0)),
    });
  };

  createTemplateClone = () => {
    this.setState(
      {
        cloneModal: {
          ...this.state.cloneModal,
          inProgress: true,
        },
      },
      () => {
        const id = this.state.cloneModal.template.id;
        let component_json_temp = {};
        let html_file_temp = "";

        TemplateService.retrieveTemplate(id)
          .then(async (templateDetail) => {
            console.log(templateDetail);

            const post_data = {
              name: this.state.cloneModal.templateName,
              description: `Copy of ${templateDetail.data.name}`,
              predefined: false,
              templateType: templateDetail.data.templateType,
              eventType: templateDetail.data.eventType,
              preview: {
                id: "",
                url: "",
              },
              modules: [],
              componentFileId: "",
              htmlFileId: "",
            };
            if (templateDetail.data?.hasOwnProperty("componentFile")) {
              const componentData = await axios.get(
                templateDetail.data?.componentFile?.url,
                {
                  onDownloadProgress: async (progressEvent) => {
                    const total = parseFloat(progressEvent.total);
                    const current = progressEvent.loaded;
                    let percentCompleted = Math.floor((current / total) * 100);
                    this.cloneProgress(parseInt(percentCompleted / 4), 0);
                  },
                }
              );
              if (componentData.data) {
                component_json_temp = componentData.data;
              }
            }
            if (templateDetail.data?.hasOwnProperty("htmlFile")) {
              const htmlData = await axios.get(
                templateDetail.data?.htmlFile?.url,
                {
                  onDownloadProgress: async (progressEvent) => {
                    const total = parseFloat(progressEvent.total);
                    const current = progressEvent.loaded;
                    let percentCompleted = Math.floor((current / total) * 100);
                    this.cloneProgress(parseInt(percentCompleted / 4), 25);
                  },
                }
              );
              if (htmlData.data) {
                html_file_temp = htmlData.data;
              }
            }
            /** Component JSON File */
            const component_json = new Blob(
              [JSON.stringify(component_json_temp)],
              { type: "application/json" }
            );
            const component_file = new File([component_json], "grapesJS.json", {
              type: "application/json",
            });

            /** HTML File */
            const html_json = new Blob([html_file_temp], { type: "text/html" });
            const html_file = new File([html_json], "index.html", {
              type: "text/html",
            });

            TemplateService.getPresignedUrl({
              entity: "TEMPLATE",
              fileExtension: "json",
            })
              .then(async (grapesJsSignedUrl) => {
                const signedUrl = grapesJsSignedUrl.data.signedUrl;
                TemplateService.cloudUpload(
                  signedUrl,
                  component_file,
                  this.fileProgress
                )
                  .then((grapesJsS3Res) => {
                    const grapesJsAssetData = {
                      description: `component_${this.templateId}`,
                      originalFileAndExtension: "grapesJS.json",
                      type: "DOCUMENT",
                      url: signedUrl,
                    };
                    TemplateService.getAssetData(grapesJsAssetData, "TEMPLATE")
                      .then((grapesJsAssetRes) => {
                        /**
                         * ==================================================
                         * ===              HTML FILE START               ===
                         * ==================================================
                         */
                        TemplateService.getPresignedUrl({
                          entity: "TEMPLATE",
                          fileExtension: "html",
                        })
                          .then(async (htmlSignedUrl) => {
                            const url = htmlSignedUrl.data.signedUrl;
                            TemplateService.cloudUpload(
                              url,
                              html_file,
                              (event) => this.fileProgress(event, true)
                            )
                              .then((htmlS3Res) => {
                                const htmlAssetData = {
                                  description: `html_${this.templateId}`,
                                  originalFileAndExtension: "index.html",
                                  type: "DOCUMENT",
                                  url,
                                };
                                TemplateService.getAssetData(
                                  htmlAssetData,
                                  "TEMPLATE"
                                )
                                  .then((htmlAssetRes) => {
                                    post_data["componentFileId"] =
                                      grapesJsAssetRes.data.id;
                                    post_data["htmlFileId"] =
                                      htmlAssetRes.data.id;

                                    TemplateService.createNewTemplate(post_data)
                                      .then((newTemplate) => {
                                        let temp =
                                          templateDetail.data.templateType.toLowerCase();
                                        if (temp === "message_template") {
                                          temp = "campaign";
                                        }
                                        // this.props.router.navigate(
                                        //   `/template/${
                                        //     temp === "campaign"
                                        //       ? "campaign"
                                        //       : temp === "pop_up"
                                        //       ? "popup"
                                        //       : "website"
                                        //   }/edit/${newTemplate.data.id}`,
                                        //   {
                                        //     state: {
                                        //       tab: this.props.router.params.tab,
                                        //     },
                                        //   }
                                        // );
                                        if (this.props.cloneCompleted) {
                                          this.props.cloneCompleted(
                                            ...newTemplate.data
                                          );
                                        }

                                        this.closeCloneModal();
                                        window.location.reload();
                                      })
                                      .catch((error) => {
                                        this.stopCloneProgress();
                                      });
                                  })
                                  .catch((error) => {
                                    this.stopCloneProgress();
                                  });
                              })
                              .catch((error) => {
                                this.stopCloneProgress();
                              });
                          })
                          .catch((error) => {
                            this.stopCloneProgress();
                          });
                        /**
                         * ==================================================
                         * ===               HTML FILE END                ===
                         * ==================================================
                         */
                      })
                      .catch((error) => {
                        this.stopCloneProgress();
                      });
                  })
                  .catch((error) => {
                    this.stopCloneProgress();
                  });
              })
              .catch((error) => {
                this.stopCloneProgress();
              });
          })
          .catch((error) => {
            this.stopCloneProgress();
          });
      }
    );
  };

  render() {
    return (
      <div>
        {/* Clone Modal */}
        <Modal
          show={this.state.cloneModal.show}
          onHide={this.closeCloneModal}
          className="template-clone-modal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            className={`${
              this.state.cloneModal.inProgress ? "disable-close-button" : ""
            }`}
            closeButton
          >
            <Modal.Title className="h5 mb-0">Copy Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.cloneModal.inProgress ? (
              <div className="file-uploading">
                <div className="text-center fs-4 fw-light mb-2">
                  Clone inprogress
                </div>
                <ProgressBar
                  animated
                  variant="success"
                  now={this.state.clonePercentage}
                  label={`${this.state.clonePercentage}%`}
                />
              </div>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>Copy Name</Form.Label>
                <Form.Control
                  placeholder="Template Name"
                  size="sm"
                  value={this.state.cloneModal.templateName}
                  onChange={this.templateNameChange}
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              disabled={this.state.cloneModal.inProgress}
              onClick={this.closeCloneModal}
            >
              Cancel
            </Button>
            {this.state.cloneModal.inProgress ? (
              <Button variant="info" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  size="sm"
                />
              </Button>
            ) : (
              <Button variant="info" onClick={this.createTemplateClone}>
                Make a Copy
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(TemplateCloneModal));
